import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class TagsProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/tags', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/tags/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/tags', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/tags/${payload.id}`, payload)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/tags/${id}`)
  }
}

export default TagsProvider
